// CustomLoader.js
import React from 'react';
import './CustomLoader.css'; // Import the CSS for the loader

const CustomLoader = () => {
  return (
    <div className="loader"></div>
  );
};

export default CustomLoader;
