import React, { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion';
import Footer from "../footer/footer"; // Ensure this is a valid import
import brake from '../brake.jpg';
import gear from '../gearbox.jpg';
import exhaust from '../exhaust.jpg';
import engine from '../engine.jpg';
import suspension from '../suspension.jpg';
import timingBelt from '../timing belt.jpg';
import welding from '../welding.jpg';
import { Typography, Box, Grid, CardContent ,Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card } from "antd";
import { lightGreen } from "@mui/material/colors";

const services = [
  {
    title: "Brake Repair",
    description: "What if your car’s brakes fail in the middle of the road? The thought is enough to run the chills down your spine, but not anymore! At Stapleford Autocentre, we can fix the issue quickly while ensuring your safety and satisfaction.",
    img: brake,
  },
  {
    title: "GearBox Repair",
    description: "We at Stapleford Autocentre offer an exceptional experience to car owners like you to ensure your convenience and satisfaction. We are an authorized M.O.T. testing facility catering to your needs.",
    img: gear,
  },
  {
    title: "Exhaust Repair",
    description: "The exhaust system in your car keeps the pollutants away from the engine and out of the exhaust pipe. Our experience strives to keep the exhaust system in perfect condition.",
    img: exhaust,
  },
  {
    title: "Engine Repair",
    description: "Regular maintenance will guarantee that your engine operates well and is healthy. We offer high-quality engine repair services at affordable costs.",
    img: engine,
  },
  {
    title: "Suspension Repair",
    description: "The suspension system in your car is crucial for a smooth ride. Our team identifies and fixes any issues to keep your ride smooth.",
    img: suspension,
  },
  {
    title: "Timing Belt Replacement",
    description: "The timing belt is an integral part of your car’s engine that operates the camshafts. Replacement is critical to avoid engine damage.",
    img: timingBelt,
  },
  {
    title: "Welding",
    description: "Welding is an excellent option to fix your vehicle’s body in case of any damage. Our specialists can repair your car’s damaged parts quickly.",
    img: welding,
  },
];

function Services() {
  const sections = useRef([]);
  const [visibleSections, setVisibleSections] = useState(Array(services.length).fill(false));

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const index = sections.current.indexOf(entry.target);
        if (entry.isIntersecting) {
          setVisibleSections(prev => {
            const updated = [...prev];
            updated[index] = true;
            return updated;
          });
        }
      });
    });

    sections.current.forEach(section => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.current.forEach(section => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <section className="nav-link-image mt-2">
        <div className="text-container">
          <h4>Services</h4>
        </div>
      </section>
      <section style={{ padding: '2rem' }}>
         <Box sx={{ padding: '2rem',borderRadius: '8px', }}>
      
      {/* Expansion Panel for Detailed Information */}
      <Box sx={{ marginTop: '2rem'}}>
        <Accordion> 
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{fontSize:40 ,fontWeight:'bold',color:'#435ef7'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
  60 Points Includes
</Typography>

          </AccordionSummary>
          <AccordionDetails sx={{backgroundColor: '#F1F3F8'}}>
          <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <Typography variant="h2" component="h2" gutterBottom sx={{ color: '#333', fontWeight: 'bold', textTransform: 'uppercase' }}>
          We understand your needs better.
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#555', lineHeight: 1.6 }}>
          We are committed to providing exceptional car maintenance <br />
          and repair services, leveraging state-of-the-art tools & equipment. <br />
          We don’t compromise on service quality.
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center mt-5"
      >
        <Typography variant="h2" component="h2" gutterBottom sx={{ color: '#333', fontWeight: 'bold', textTransform: 'uppercase' }}>
          60 Point Check.
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: 1.6 }}>
          Our 60-Pt checks instigate every part of your vehicle to ensure it <br />
          operates the way you want. This includes checking your fluid levels,<br />
          heating system, tyres, belts and hoses, brakes, wiper blades,<br />
          and overall engine performance (checked via road test by one <br />
          of our experienced technicians). Whether you’re going 100KM or 1000KM, <br />
          it’s important your vehicle can get you there.
        </Typography>
      </motion.div>

          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mt-5"
        >
          {/* <Typography variant="h2" component="h2" gutterBottom>
            60-Pt Checks Include
          </Typography> */}
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ padding: '2rem' }}>
      <Grid container spacing={2} justifyContent="center">
        {["Under The Hood", "Performance", "Interior", "Exterior"].map((title, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              variant="outlined" 
              style={{ 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
                minHeight: '190px', // Set fixed height
                display: 'flex', // Enable flexbox layout
                flexDirection: 'column', // Stack content vertically
                justifyContent: 'space-between', // Space between title and description
              }}
            >
              <CardContent>
                <Typography variant="h6" component="h3">
                  <CheckCircleIcon style={{ fontSize: 25, marginBottom: 6 }} color="primary" /> {title}
                </Typography>
                <Typography variant="body2" className="mt-2">
                  {index === 0 ? "Air filters, Belts, Hoses, Battery, Radiator Components" :
                   index === 1 ? "Engine, Transmission, Alignment, Braking Performance" :
                   index === 2 ? "Seat belts, Horn, Heating Systems, Central locking, Dash Controls" :
                   "Doors, Lighting, Windshield Components"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
        </motion.div>
      </section>

      <section className="service-section" style={{ padding: '2rem 0' }}>
        <Grid container spacing={4} className="d-flex justify-content-center">
          {services.map((service, index) => (
            <Grid item md={6} key={index} ref={el => (sections.current[index] = el)}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: visibleSections[index] ? 1 : 0, y: visibleSections[index] ? 0 : 50 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  marginBottom: '1rem',
                  transition: 'transform 0.3s',
                  height: isMobile ? '300px' : 'auto', // Adjust height for mobile
                }}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.03)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
              >
                <img src={service.img} alt={service.title} style={{ width: '100%', height: 'auto', filter: isMobile ? 'brightness(100%)' : 'brightness(70%)' }} />
                <Box
                  padding={2}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <h5 style={{ color: '#435ef7', fontSize: isMobile ? '1.8rem' : '2.4rem', fontWeight: 600 }}>
                    {service.title}
                  </h5>
                  <h5 style={{ color: 'white', fontSize: isMobile ? '1.5rem' : '1.9rem', fontWeight: 400 }}>
                    {service.description}
                  </h5>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </section>

      <Footer />
    </>
  );
}

export default Services;
