import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import HomePage from './pages/homePage/homePage';
import AboutUs from './pages/aboutUs/aboutsUs';
import FaqComponent from './pages/faqPage/faqPage';
import Services from './pages/services/services';
import StapleFordContact from './pages/contactUs/stapleFordContact';
import StapleFordNav from './pages/stapleFordNavbar/stapleFordNavbar';
import GeneralRepairs from './pages/generalRepairs/generalRepairs';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import Terms from './pages/termsandservices/termsandservices';
import InvoiceGenerator from './pages/invoicePage/invoicePage';
import ReviewCard from './pages/reviewPage/reviewPage';
import CustomLoader from './pages/customerLoader/customerLoader'; // Import the custom loader

// NotFound Component
const NotFound = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h1>404 - Not Found</h1>
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0); // Scroll to top on location change
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds loader

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [location]);

  return (
    <>
      <StapleFordNav /> {/* Render Navbar on all pages */}
      {loading ? (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomLoader /> {/* Center loader */}
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} /> {/* Redirect to Home */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/faq" element={<FaqComponent />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<StapleFordContact />} />
          <Route path="/generalRepairs" element={<GeneralRepairs />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/invoice" element={<InvoiceGenerator />} />
          <Route path="/review" element={<ReviewCard />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
        </Routes>
      )}
    </>
  );
}

// Wrap App with Router
const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
