import React from 'react';
import { Collapse, Typography, Row, Col } from 'antd';
import './faq.css'; // Import your custom CSS file
import Footer from '../footer/footer';

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

const faqData = [
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of car services including oil changes, brake repairs, tire rotations, engine diagnostics, and more. For a full list of our services, please visit our Services page.'
  },
  {
    question: 'How can I schedule an appointment?',
    answer: 'You can schedule an appointment by calling us directly at (123) 456-7890, or by using our online booking system available on the Contact page.'
  },
  {
    question: 'What are your business hours?',
    answer: 'Our business hours are Monday to Friday from 8:00 AM to 6:00 PM, and Saturday from 9:00 AM to 3:00 PM. We are closed on Sundays.'
  },
  {
    question: 'Do you offer any warranties on your services?',
    answer: 'Yes, we offer a 12-month or 12,000-mile warranty on all of our services, whichever comes first. Please contact us for more details.'
  },
  {
    question: 'Can I get a loaner car while my car is being serviced?',
    answer: 'Yes, we offer loaner cars for certain types of services. Please inquire about availability when scheduling your appointment.'
  }
];

const FAQ = () => {
  return (
    <>
    <section className="nav-link-image">
        <div className="text-container">
          <h4>FAQ's</h4>
        </div>
      </section>
    <div style={{ padding: '30px' }}>
      <Row justify="center">
        <Col span={24} md={16} lg={12}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
            Frequently Asked Questions
          </Title>
          <Collapse accordion>
            {faqData.map((faq, index) => (
              <Panel
                header={<div className="panel-header">{faq.question}</div>}
                key={index}
                className="panel"
              >
                <Paragraph className="panel-content">{faq.answer}</Paragraph>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </div>
    <Footer />
    </>
  );
};

export default FAQ;
