import React from "react";
import './footer.css'
import logo from '../logo.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Link } from "react-router-dom";
function Footer() {
    return (
        <div className="footer-padding">
            <section class="footer">

                <div class="box-container">

                    <div class="box">
                        <img
                            src={logo} // Use imported image
                            alt="Doctor 1"
                            style={{ width: '50%' }}
                        />
                        <a>We Specialise in car repair services</a>
                        <TwitterIcon style={{ fontSize: 50, color: '#435ef7' }} />
                        <FacebookRoundedIcon style={{ fontSize: 50, color: '#435ef7' }} />
                    </div>

                    <div class="box">
                        <h2>Quick Link</h2>
                        <Link to="/" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Home
                        </Link>
                        <Link to="/about" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            About Us
                        </Link>
                        <Link to="/services" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Services
                        </Link>
                        <Link to="/review" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Review
                        </Link>
                        <Link to="/contact" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Contact
                        </Link>

                    </div>
                    <div class="box">
                        <h2>Support</h2>
                        <Link to="/privacyPolicy" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Privacy Policy
                        </Link>
                        <Link to="/terms" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            Terms of Service
                        </Link>
                        <Link to="/faq" style={{ textDecoration: 'none', color: 'ffffff' }}>
                            FAQ
                        </Link>
                    </div>
                    <div class="box">
                        <h2>contact info</h2>
                        <a href="tel:01159394617" style={{ textDecoration: 'none' }}>
    <CallRoundedIcon style={{ fontSize: 30, color: '#435ef7', marginRight: '10px' }} />
    0115-939-4617
</a>
                       
<a href="mailto:staplefordautocentre@gmail.com" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', }}>
    <ForwardToInboxRoundedIcon style={{ fontSize: 23, color: '#435ef7', marginRight: '10px' }} />
    staplefordautocentre@gmail.com 
</a>

<a href="https://www.google.com/maps/search/?api=1&query=Victoria+garage+Victoria+street+Stapleford+Nottinghamshire+NG9+7AP+UK" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <LocationOnRoundedIcon style={{ fontSize: 30, color: '#435ef7', marginRight: '10px' }} />
    Victoria garage Victoria street Stapleford Nottinghamshire NG9 7AP UK
</a>
                    </div>



                </div>

            </section>
        </div>
    )
}

export default Footer