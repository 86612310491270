import React, { useState } from 'react';
import {
  Container,
  TextField,
  Typography,
  Paper,
  Grid,
  Box,
  Avatar,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import EditIcon from '@mui/icons-material/Edit'; // Edit icon for editing bill info
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useSnackbar } from 'notistack';
import StaplefordLogo from '../logo.png'

const InvoiceGenerator = () => {
  const [items, setItems] = useState([{ description: '', quantity: '', price: '' }]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
  const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0]);
  const [loading, setLoading] = useState(false);
  const [billTo, setBillTo] = useState({
    name: '',
    company: '',
    country: '',
    email: '',
    phone: '',
    address: ''
  });
  const [notes, setNotes] = useState('');
  const [openBillDialog, setOpenBillDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleItemChange = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = event.target.value;
    setItems(values);
  };

  const handleAddItem = () => {
    setItems([...items, { description: '', quantity: '', price: '' }]);
  };

  const handleRemoveItem = (index) => {
    const values = items.filter((_, i) => i !== index);
    setItems(values);
    enqueueSnackbar('Item removed', { variant: 'info' });
  };

  const calculateTotal = (items) => {
    return items.reduce((sum, item) => 
      sum + (parseFloat(item.price) || 0) * (parseFloat(item.quantity) || 0), 0
    );
  };

  const subtotal = calculateTotal(items);
  const vatRate = 0.20;
  const vatAmount = subtotal * vatRate;
  const totalWithVAT = subtotal + vatAmount;


  const generatePDF = () => {
    setLoading(true);
  
    // Create a new image object
    const img = new Image();
    img.src = StaplefordLogo; // Set the source to your logo
  
    img.onload = () => {
      try {
        const doc = new jsPDF();
  
        const imgWidth = 50;
        const imgHeight = 30;
        const x = (doc.internal.pageSize.width - imgWidth) / 2;
        const y = 17;
  
        // Add logo to the PDF
        doc.addImage(img, 'PNG', x, y, imgWidth, imgHeight);
  
        // Set title and address
        doc.setFontSize(18);
        doc.text('Stapleford Autocentre', 14, 70);
        doc.setFont('courier', 'italic');
        doc.text('Victoria St, Stapleford', 14, 80);
        doc.setFont('normal');
        doc.text('Nottingham NG9 7AP', 14, 90);
        doc.text('United Kingdom', 14, 100);
  
        doc.setFont('normal'); // Ensure the font is set to normal
        doc.setFontSize(12);
        doc.text(`Invoice Number: ${invoiceNumber || 'N/A'}`, 14, 115);
        doc.text(`Invoice Date: ${invoiceDate || 'N/A'}`, 14, 125);
        doc.text(`Due Date: ${dueDate || 'N/A'}`, 14, 135);
  
        // Bill To data
        const billToData = [
          ['Name:', billTo.name || ''],
          ['Company:', billTo.company || ''],
          ['Country:', billTo.country || ''],
          ['Email:', billTo.email || ''],
          ['Phone:', billTo.phone || ''],
          ['Address:', billTo.address || ''],
        ];
  
        doc.setFontSize(12);
        doc.text('Bill To:', 14, 150);
        doc.autoTable({
          startY: 160,
          body: billToData,
          theme: 'grid',
          styles: { cellPadding: 2, fontSize: 10 },
          columnStyles: { 0: { cellWidth: 60 }, 1: { cellWidth: 140 } },
          margin: { left: 14 },
        });
  
        // Items table
        const itemsTable = items.map(item => [
          item.description || '',
          item.quantity || '',
          item.price || '',
          ((parseFloat(item.price) || 0) * (parseFloat(item.quantity) || 0)).toFixed(2) || '0.00',
        ]);
  
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          head: [['Description', 'Quantity', 'Price', 'Total']],
          body: itemsTable,
          foot: [['', '', 'Subtotal:', subtotal.toFixed(2)]],
          margin: { top: 10 },
        });
  
        // VAT and Total Amount Due
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          body: [['', '', 'VAT (20%):', vatAmount.toFixed(2)]],
          margin: { top: 10 },
        });
  
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          body: [['', '', 'Total Amount Due:', totalWithVAT.toFixed(2)]],
          margin: { top: 10 },
        });
  
        // Notes section
        const notesStartY = doc.lastAutoTable.finalY + 20;
        const notesHeight = doc.getTextDimensions(notes).h;
  
        doc.setFontSize(12);
        if (notesHeight > doc.internal.pageSize.height - notesStartY - 20) {
          doc.addPage();
          doc.text('Notes:', 14, 20);
          doc.text(notes, 14, 30, { maxWidth: 180, lineHeightFactor: 1.2 });
        } else {
          doc.text('Notes:', 14, notesStartY);
          doc.text(notes, 14, notesStartY + 10, { maxWidth: 180, lineHeightFactor: 1.2 });
        }
  
        // Create PDF Blob and URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        // Set a delay of 2 seconds
        setTimeout(() => {
          // Open PDF in a new tab
          window.open(pdfUrl, '_blank');
  
          // Download PDF
          const a = document.createElement('a');
          a.href = pdfUrl;
          a.download = 'invoice.pdf'; // Set the file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          setLoading(false);
        }, 2000); // Delay for 2 seconds
  
      } catch (error) {
        console.error('Error generating PDF:', error);
        enqueueSnackbar('Error generating PDF', { variant: 'error' });
        setLoading(false);
      }
    };
  
    img.onerror = () => {
      console.error('Failed to load logo image');
      enqueueSnackbar('Error: Failed to load logo image', { variant: 'error' });
      setLoading(false);
    };
  };
  
  
  
  
  const handleOpenBillDialog = () => {
    setOpenBillDialog(true);
  };

  const handleCloseBillDialog = () => {
    setBillTo({ // Reset fields to empty on cancel
      name: '',
      company: '',
      country: '',
      email: '',
      phone: '',
      address: ''
    });
    setOpenBillDialog(false); // Close the dialog
  };

  const handleSaveBillTo = () => {
    setOpenBillDialog(false);
  };

  return (
    <section className='invoice-page mt-3'>
    <Container>
      <Paper className='invoice-dialogue' style={{ padding: '30px', marginBottom: '24px'}}>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Invoice Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              InputLabelProps={{ style: { fontSize: 17 ,color:'darkblue',fontWeight:600} }} // Increase label size
              InputProps={{ style: { fontSize: 16 } }} // Increase input text size
              focused // Keep input focused style
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Invoice Date"
              type="date"
              fullWidth
              margin="normal"
              variant="outlined"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              InputLabelProps={{ style: { fontSize: 17 ,color:'darkblue',fontWeight:600} }} // Increase label size
              InputProps={{ style: { fontSize: 16 } }} // Increase input text size
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Due Date"
              type="date"
              fullWidth
              margin="normal"
              variant="outlined"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              InputLabelProps={{ style: { fontSize: 17 ,color:'darkblue',fontWeight:600} }} // Increase label size
              InputProps={{ style: { fontSize: 16 } }} // Increase input text size
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mb={2}>
          <Grid item xs={12}>
         <p> <span
  style={{
    display: 'inline-block',
    cursor: 'pointer',
    color: 'darkblue',
    fontWeight: '400',
    fontSize: 22,
  }}
  onClick={handleOpenBillDialog} // Move the click handler here
>
  Edit Bill To
  <EditIcon className='mx-3'
    style={{ cursor: 'pointer', color: 'darkblue', fontSize: 30 }}
  />
</span></p>

          </Grid>
        </Grid>

        {billTo.name && (
          <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
          <p><span style={{color:'darkblue', fontWeight:'600'}}>Bill To</span></p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <p>Name : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.name}</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Company : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.company}</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Country : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.country}</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Address : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.address}</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Email : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.email}</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Phone : <span style={{color:'darkblue', fontWeight:'400'}}>{billTo.phone}</span></p>
            </Grid>
          </Grid>
        </Paper>
        
        )}

        <Grid container spacing={3} mb={3}>
          {items.map((item, index) => (
           <Grid item xs={12} key={index} container spacing={1}>
           <Grid item xs={12} mt={2} sm={4}>
             <TextField
               label="Description"
               name="description"
               value={item.description}
               onChange={(e) => handleItemChange(index, e)}
               fullWidth
               InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }}
               InputProps={{ style: { fontSize: 16 } }}
             />
           </Grid>
           <Grid item xs={12} mt={2} sm={3}>
             <TextField
               label="Quantity"
               name="quantity"
               type="number"
               value={item.quantity}
               onChange={(e) => handleItemChange(index, e)}
               fullWidth
               InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }}
               InputProps={{ style: { fontSize: 16 } }}
             />
           </Grid>
           <Grid item xs={12} mt={2} sm={3}>
             <TextField
               label="Price"
               name="price"
               type="number"
               value={item.price}
               onChange={(e) => handleItemChange(index, e)}
               fullWidth
               InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }}
               InputProps={{ style: { fontSize: 16 } }}
             />
           </Grid>
           <Grid item xs={12} sm={2} mt={3}>
             <CloseIcon
               onClick={() => handleRemoveItem(index)}
               style={{ cursor: 'pointer', color: 'red', fontSize: 35, fontWeight: 'bolder' }}
             />
           </Grid>
         </Grid>
         
          ))}
        </Grid>

        <Box mt={2}>
          <span
            onClick={handleAddItem}
            style={{
              cursor: 'pointer',
              color: 'darkblue',
    fontWeight: '400',
    fontSize: 22,
            }}
          >
            Add Item
            <AddIcon className='mx-3'
    style={{ cursor: 'pointer', color: 'darkblue', fontSize: 30 }}
  />
          </span>
        </Box>

        <Box mt={3}>
          <p>Subtotal : <span style={{color:'darkblue',fontWeight:'600'}} className='mx-4'>£{subtotal.toFixed(2)}</span></p>
          <p>VAT (20%) : <span style={{color:'darkblue',fontWeight:'600'}} className='mx-4'>£{vatAmount.toFixed(2)}</span></p>
          <p>TotalDue : <span style={{color:'darkblue',fontWeight:'600'}} className='mx-4'>£{totalWithVAT.toFixed(2)}</span></p>
        </Box>

        <Box mt={2}>
          <TextField
            label="Notes"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            InputLabelProps={{ style: { fontSize: 17 ,color:'darkblue',fontWeight:600} }} // Increase label size
            InputProps={{ style: { fontSize: 16 } }} // Increase input text size
          />
        </Box>

        <Box mt={2}>
  {loading ? (
    <CircularProgress size={24} />
  ) : (
    <span
      onClick={generatePDF}
      style={{ cursor: 'pointer', color: 'darkblue', fontSize: 20 }}
    >
      Generate PDF
      <DescriptionIcon
        className='mx-3'
        style={{ cursor: 'pointer', color: 'darkblue', fontSize: 30 }}
      />
    </span>
  )}
</Box>

      </Paper>
      <Dialog 
       PaperProps={{
        className: 'custom-dialog',
      }} 
      BackdropProps={{
        className: 'custom-backdrop', // Apply custom backdrop class
      }}
      open={openBillDialog} onClose={handleCloseBillDialog}>
  <DialogContent>
    <TextField
      label="Name"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.name}
      onChange={(e) => setBillTo({ ...billTo, name: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
    <TextField
      label="Company"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.company}
      onChange={(e) => setBillTo({ ...billTo, company: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
    <TextField
      label="Country"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.country}
      onChange={(e) => setBillTo({ ...billTo, country: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
    <TextField
      label="Email"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.email}
      onChange={(e) => setBillTo({ ...billTo, email: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
    <TextField
      label="Phone"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.phone}
      onChange={(e) => setBillTo({ ...billTo, phone: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
    <TextField
      label="Address"
      fullWidth
      margin="normal"
      variant="outlined"
      value={billTo.address}
      onChange={(e) => setBillTo({ ...billTo, address: e.target.value })}
      InputLabelProps={{ style: { fontSize: 17, color: 'darkblue', fontWeight: 600 } }} // Label styles
      InputProps={{ style: { fontSize: 16 } }} // Input styles
    />
  </DialogContent>
  <DialogActions>
    <span className='mx-3' onClick={handleCloseBillDialog} style={{ cursor: 'pointer', color: 'darkblue', fontWeight: '600', fontSize: 22 }}>Cancel</span>
    <span className='mx-3' onClick={handleSaveBillTo} style={{ cursor: 'pointer', color: 'darkblue', fontWeight: '600', fontSize: 22 }}>Save</span>
  </DialogActions>
</Dialog>

    </Container>
    </section>
  );
};

export default InvoiceGenerator;
