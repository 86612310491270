import React, { useEffect, useState } from "react";
import { Card } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from "../footer/footer";
import { TextField, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';

function StapleFordContact() {
    const mapStyle = {
        width: '100%',
        height: '600px'
    };

    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const formElement = document.getElementById('contact-form');
        const mapElement = document.getElementById('contact-map');

        if (formElement) {
            const formTop = formElement.getBoundingClientRect().top + window.scrollY;
            const mapTop = mapElement.getBoundingClientRect().top + window.scrollY;

            setInView(scrollPosition > formTop || scrollPosition > mapTop);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call once to set initial state

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <section className="nav-link-image mt-2">
                <div className="text-container">
                    <h4>Contact Us</h4>
                </div>
            </section>
            <section className="">
                <div className="text-center">
                    <h2 className="">Get in Touch.</h2>
                    <p>
                        Contact us today if you have any concerns or queries regarding our services.
                    </p>
                </div>
            </section>
            <section className="service-section">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="row">
                        <motion.div
                            id="contact-form"
                            className="col-md-9"
                            initial={{ opacity: 0, x: -50 }} // Slide in from left
                            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <form style={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="First Name"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            InputProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Last Name"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            InputProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Phone"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            type="number"
                                            placeholder="(000) 000-0000"
                                            helperText="Please enter a valid phone number."
                                            InputLabelProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            InputProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            FormHelperTextProps={{
                                                style: { fontSize: '1rem', color: '#435ef7' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            placeholder="example@example.com"
                                            InputLabelProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            InputProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Message"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            helperText="Describe your research program and development goals."
                                            InputLabelProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            InputProps={{
                                                style: { fontSize: '2rem', color: '#435ef7' },
                                            }}
                                            FormHelperTextProps={{
                                                style: { fontSize: '1rem', color: '#435ef7' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            style={{ backgroundColor: '#435ef5', color: 'white', padding: 20, fontSize: '2rem' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </motion.div>
                        <motion.div
                            id="contact-map"
                            className="col-md-3"
                            initial={{ opacity: 0, x: 50 }} // Slide in from right
                            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2405.1291468064987!2d-1.275482!3d52.928103!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879e93e3847c113%3A0xd0eef76be3d64abc!2sStapleford%20Autocentre!5e0!3m2!1sen!2sus!4v1725218676887!5m2!1sen!2sus"
                                    style={mapStyle}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default StapleFordContact;
