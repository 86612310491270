import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../logo.png';
import './StapleFordNav.css'; // Import a CSS file for additional styling

const { Header } = Layout;

const StapleFordNav = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();

  const showDrawer = () => setDrawerVisible(true);
  const onClose = () => setDrawerVisible(false);

  const handleResize = () => setIsMobile(window.innerWidth <= 1280);

  const handleScroll = () => {
    const sections = ['home', 'about', 'services', 'contact'];
    const currentScrollPosition = window.scrollY;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const sectionTop = element.offsetTop;
        const sectionBottom = sectionTop + element.clientHeight;

        if (currentScrollPosition >= sectionTop && currentScrollPosition < sectionBottom) {
          setActiveTab(section);
        }
      }
    });
  };

  useEffect(() => {
    const path = location.pathname.split('/')[1] || 'home';
    setActiveTab(path);

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  const handleMenuClick = (tabName, path) => {
    setActiveTab(tabName);
    navigate(path);
    if (isMobile) {
      setDrawerVisible(false);
    }
  };

  return (
    <Layout>
      <Header
        style={{
          marginTop:'5px',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
          background: drawerVisible ? 'transparent' : 'white',
          position: 'relative',
        }}
      >
        
        {/* Logo centered */}
        <div className="logo" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', color: 'black' }}>
          <img src={logo} alt="logo" style={{ width: '68%' }} />
        </div>
        
        {/* Menu icon for mobile */}
        {isMobile && (
          <div className="menu-icon" style={{ position: 'absolute', left: '16px', color: 'black', cursor: 'pointer' }}>
            <MenuOutlined onClick={showDrawer} />
          </div>
        )}

        {/* Desktop Menu */}
        <Menu
          mode="horizontal"
          theme="light"
          style={{
            lineHeight: '64px',
            flex: 1,
            display: isMobile ? 'none' : 'flex',
            justifyContent: 'space-between',
            padding: 0,
            border:'none'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start',marginLeft:'55px', }}>
            <Menu.Item
              key="home"
              className={`menu-item ${activeTab === 'home' ? 'active' : ''}`}
              onClick={() => handleMenuClick('home', '/') }
            >
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item
              key="about"
              className={`menu-item ${activeTab === 'about' ? 'active' : ''}`}
              onClick={() => handleMenuClick('about', '/about')}
            >
              <Link to="/about">About</Link>
            </Menu.Item>
          </div>
          <div>
            <Menu.Item
              key="services"
              className={`menu-item ${activeTab === 'services' ? 'active' : ''}`}
              onClick={() => handleMenuClick('services', '/services')}
            >
              <Link to="/services">Services</Link>
            </Menu.Item>
            <Menu.Item
              key="review"
              className={`menu-item ${activeTab === 'review' ? 'active' : ''}`}
              onClick={() => handleMenuClick('review', '/review')}
            >
              <Link to="/review">Reviews</Link>
            </Menu.Item>
            <Menu.Item
              key="contact"
              className={`menu-item ${activeTab === 'contact' ? 'active' : ''}`}
              onClick={() => handleMenuClick('contact', '/contact')}
            >
              <Link to="/contact">Contact</Link>
            </Menu.Item>
          </div>
        </Menu>

        {/* Drawer for mobile menu */}
        <Drawer
          title="Menu"
          placement="left"
          closable={false}
          onClose={onClose}
          visible={drawerVisible}
          style={{ zIndex: 1000, background: 'linear-gradient(135deg, #e2e2e2 0%, #b0b0b0 100%)' }}
        >
          <Menu mode="inline" style={{ height: '100%', borderRight: 0, padding: 0, background: 'transparent' }}>
            <Menu.Item
              key="home"
              className={`menu-item ${activeTab === 'home' ? 'active' : ''}`}
              onClick={() => handleMenuClick('home', '/') }
            >
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item
              key="about"
              className={`menu-item ${activeTab === 'about' ? 'active' : ''}`}
              onClick={() => handleMenuClick('about', '/about')}
            >
              <Link to="/about">About</Link>
            </Menu.Item>
            <Menu.Item
              key="services"
              className={`menu-item ${activeTab === 'services' ? 'active' : ''}`}
              onClick={() => handleMenuClick('services', '/services')}
            >
              <Link to="/services">Services</Link>
            </Menu.Item>
            <Menu.Item
              key="review"
              className={`menu-item ${activeTab === 'review' ? 'active' : ''}`}
              onClick={() => handleMenuClick('review', '/review')}
            >
              <Link to="/review">Reviews</Link>
            </Menu.Item>
            <Menu.Item
              key="contact"
              className={`menu-item ${activeTab === 'contact' ? 'active' : ''}`}
              onClick={() => handleMenuClick('contact', '/contact')}
            >
              <Link to="/contact">Contact</Link>
            </Menu.Item>
          </Menu>
        </Drawer>
      </Header>
    </Layout>
  );
};

export default StapleFordNav;
