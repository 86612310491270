import React from "react";
import { Card } from 'antd';
import doctorImage from '../images/doctoreImages.jpg'
import aboutImage from '../images/randomPeople.png'
import vessel from '../lexus-hybrid-anim-1.gif'
import doctorImage1 from '../images/doctorImages.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import Footer from "../footer/footer";
function GeneralRepairs() {
  const { Meta } = Card;
  return (
  <>
      <section className="nav-link-image">
        <div className="text-container">
          <h4>General Repairs</h4>
        </div>
      </section>
      <section className="">
        <div className="row d-flex responsive mt-5">
          <div className="col-md-7">
            <h2 className="mt-5">Comprehensive Repair Solutions for All Your Needs..</h2>
            <p className="mt-4 line-height">At our service center, we offer a broad range of general repair solutions designed to address every need with precision and efficiency. Our experienced technicians are dedicated to providing top-notch repair services, utilizing advanced tools and techniques to tackle both routine maintenance and complex repairs. We understand the importance of reliable performance, which is why we focus on delivering high-quality workmanship that ensures your equipment or vehicle operates at its best. Whether you're dealing with minor fixes or major issues, our team is committed to providing thorough, dependable repairs that meet your exact requirements. With a reputation for excellence and a commitment to customer satisfaction, you can trust us to handle your repair needs with professionalism and care. Choose us for peace of mind and exceptional service every time..</p>
          </div>
          <div className="col-md-5">
            <img
              src={vessel} // Use imported image
              alt="car"
              style={{ width: '100%' }}
            />
          </div>

        </div>
      </section>

      {/* <section className="">
          <div className="d-flex align-items-center justify-content-around flex-wrap mt-5">
            <div>
              <img
                src={doctorImage} // Use imported image
                alt="Doctor 1"
                style={{ width: '60%' }}
              />
            </div>
            <div>
              <div>
                <h3>About Research Mate</h3>
                <h2 className="mt-5">Experienced.
                  Committed to Quality. Inclusive..</h2>
              </div>
              <p className="mt-4 line-height">Research Mate is a network of clinical trial investigator sites.<br /> For 17 years we have been connecting patients with our <br />trusted physicians to conduct cutting-edge studies focused on <br /> quality and compassion. We contribute to the advancement of medicine and help <br />bring groundbreaking treatments to the market — <br /> lifting up the patients that need it most around the world.</p>
              <button>Read Article</button>
            </div>
          </div>
        </section>
        <section className="count-section">
        <div className="row mt-5">
        <div className="col-md-5">
              <img
                src={doctorImage1} // Use imported image
                alt="Doctor 1"
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-md-7">
              <div className="mt-5">
                <h3>Our Mission</h3>
                <h2 className="mt-5">Experienced.
                  Committed to Quality. Inclusive..</h2>
              </div>
              <p className="mt-4 line-height">Every clinical trial we conduct is overseen by one of our own physicians — otherwise known as a Principal Investigator (PI). They believe in the importance of high-quality trials done with care in order to advance life science technologies. They work within their specialty therapeutic areas and ensure each participant is treated with compassion, safety, and professionalism.

                Patients potentially get early access to life-changing medicines — sometimes even years before their release to the general public.

                Our physicians are a part of our larger team of recruiters, clinical coordinators, site managers, patient liaisons, and more. They partner together to create an infrastructure that collaborates with sponsors and patients to establish trial confidence, exceed sponsor expectations, and ever-improve the patient experience.a</p>
            </div>

          </div>
        </section>
        <section className="card-section">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="line-height">DM Clinical is currently home to 24+ dedicated research centers.<br /> We are quickly growing in demograhphically diverse areas of the country.</h2>
            </div>
            <div className="d-flex justify-content-around align-items-center flex-wrap">
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <Diversity1OutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} />  Enrolled in a COVID-19 Vaccine Trial
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <EventNoteOutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} /> Enrolled in a COVID-19 Vaccine Trial
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <DomainAddOutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} />  Enrolled in Other Studies &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      <Footer />
    </>
  );
}

export default GeneralRepairs;
