import React, { useEffect } from "react";
import { Carousel } from 'antd';
import { Card } from 'antd';
import Slider from 'react-slick';
import { TextField, Button, MenuItem, Grid, Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomePage.css'
import step1 from '../../pages/mechanicsStep.png'
import step2 from '../../pages/mechanicsStep2.png'
import step3 from '../../pages/mechanicsStep3.png'
import engineCar from '../car.png'
import Tyre from '../aboutTyre.png'
import carPurchase from '../carPurchase.png'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Flex, Progress } from 'antd';
import Footer from "../footer/footer";
import { motion } from 'framer-motion';
import light from '../headlights.png'
import enginecoil from '../engineCoil.png'
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`; // Format as millions
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}k`; // Format as thousands
  } else {
    return num; // Format as is
  }
};

const AnimatedCount = ({ count, duration }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const { number } = useSpring({
    number: inView ? count : 0,
    config: { duration: duration },
  });

  return (
    <div ref={ref} className="col-md-3 p-3 text-center mt-3">
      <h2>
        {count === 25
          ? 'Expert Technician'
          : count === 5
            ? 'Quality Assistance'
            : count === 500000
              ? 'Competitive Prices'
              : 'Budget Friendly Tyres'}
      </h2>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <animated.h6 style={{ fontSize: '2rem', fontWeight: 'bold', marginRight: '5px' }}>
          {number.to(n => formatNumber(Math.round(n)))}
        </animated.h6>
        {count === 99 ? (
          <span style={{ fontSize: '2rem', color: '#435ef7', fontWeight: 'bolder' }}>%</span>
        ) : (
          <span style={{ fontSize: '2rem', color: '#435ef7', fontWeight: 'bolder' }}>+</span>
        )}
      </div>
      <div className="hr"></div>
      <p className="mt-3">
        {count === 25
          ? 'Our staff is fully trained & skilled to manage all your car service and maintenance needs.'
          : count === 5
            ? 'Leveraging cutting-edge tools and equipment, we ensure to provide exceptional service while maintaining the highest standards of quality and professionalism.'
            : count === 500000
              ? 'We offer market-competitive prices while delivering exceptional car repair and maintenance services.'
              : 'We offer free alignment tracking and balance on the set of 4 brand new and set of 2 brand new tyres. Similarly, we also provide part-worn tyres.'}
      </p>
    </div>
  );
};
function HomePage() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration in milliseconds
      once: false,     // Whether animation should happen only once or every time you scroll
    });
  }, []);
  const stepsSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    arrows: true, // Show navigation arrows
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 3000, // Slide will change every 3 seconds
    responsive: [
      {
        breakpoint: 1024, // Screen width <= 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // Screen width <= 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Screen width <= 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1,
    arrows: true, // Show navigation arrows
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 3000, // Slide will change every 3 seconds
    responsive: [
      {
        breakpoint: 1430, // Screen width <= 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024, // Screen width <= 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // Screen width <= 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Screen width <= 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/generalRepairs'); // Route to the desired path
  };


  const { Meta } = Card;
  return (
    <>
      <div className="margint-50">
        <motion.div
          initial={{ x: '-100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 80, damping: 20 }}
        >
          <section>
            <div className="car-section">
              <Slider {...settings}>
                {/* Slide 1 */}
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6 p-5 text-center">
                    <h1>Your Professional Auto Service Partner</h1>
                    <h3>Stapleford Autocentre is the one-stop shop for all your car repair needs. We specialize in vehicle tune-up, repair, and maintenance leveraging cutting-edge tools and equipment. </h3>
                  </div>
                  <div className="col-md-6">
                    <div className='home-carousel d-flex justify-content-around align-items-center position-relative'>
                      <img
                        src={engineCar}
                        alt="Base Image"
                        style={{ width: '100%' }}
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image'
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image2'
                      />
                      <img
                        src={enginecoil}
                        alt="Overlay"
                        className='engine-coil'
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6 p-5 text-center">
                    <h1>Professional Reliable TrustWorthy</h1>
                    <h3>Stapleford Autocentre is the one-stop shop for all your car repair needs. We specialize in vehicle tune-up, repair, and maintenance leveraging cutting-edge tools and equipment. </h3>
                  </div>
                  <div className="col-md-6">
                    <div className='home-carousel d-flex justify-content-around align-items-center position-relative'>
                      <img
                        src={engineCar}
                        alt="Base Image"
                        style={{ width: '100%' }}
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image'
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image2'
                      />
                      <img
                        src={enginecoil}
                        alt="Overlay"
                        className='engine-coil'
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6 p-5 text-center">
                    <h1>Customer Satisfaction Guaranteed</h1>
                    <h3>Stapleford Autocentre is the one-stop shop for all your car repair needs. We specialize in vehicle tune-up, repair, and maintenance leveraging cutting-edge tools and equipment. </h3>
                  </div>
                  <div className="col-md-6">
                    <div className='home-carousel d-flex justify-content-around align-items-center position-relative'>
                      <img
                        src={engineCar}
                        alt="Base Image"
                        style={{ width: '100%' }}
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image'
                      />
                      <img
                        src={light}
                        alt="Overlay"
                        className='overlay-image2'
                      />
                      <img
                        src={enginecoil}
                        alt="Overlay"
                        className='engine-coil'
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </motion.div>
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <section className="count-section mt-50">
            <div className="row mt-5">
              <div className="row">
                <AnimatedCount count={25} duration={2000} />
                <AnimatedCount count={5} duration={2000} />
                <AnimatedCount count={500000} duration={2000} />
                <AnimatedCount count={99} duration={2000} />
              </div>
            </div>
          </section>

          <section className="" data-aos="slide-up">
            <div className="d-flex align-items-center justify-content-around flex-wrap mt-5">
              <div>
                <img
                  src={Tyre} // Use imported image
                  alt="Doctor 1"
                  style={{ width: '100%' }}
                />
              </div>
              <div>
                <div>
                  <h1>About Us</h1>
                  <h3 className="mt-3">Your go-to vehicle repair partner..</h3>
                </div>
                <p className="mt-4 line-height">We at Stapleford Autocentre offer an exceptional experience <br /> to car owners like you to ensure your convenience and satisfaction. <br />With years of experience and expertise, we specialize <br /> in effective automotive repair and maintenance services..</p>
                <div className="row text-center">
                  <div className="col-md-6 mt-3 d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center">
                      <Flex gap="small" wrap>
                        <Progress type="circle" percent={90} />
                      </Flex>
                      <p className="mt-4">Highly Qualified Experts</p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3 d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center">
                      <Flex gap="small" wrap>
                        <Progress type="circle" percent={99} />
                      </Flex>
                      <p className="mt-4">Clean, Modern Facility</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </motion.div>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <section data-aos="slide-up">
            <div data-aos="slide-up" className='mt-50'>
              <h1 >Experienced Mechanics</h1>
              <p className="line-height">
                We have onboarded a team of expert mechanics and technicians to ensure your satisfaction. <br /> They can tackle all major or minor car repair and maintenance jobs professionally.
              </p>
            </div>
            <div className='slider-container mt-50'>
              <Slider {...stepsSetting}>
                <div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <img
                      src={step1}
                      className='image-border' // Add your custom class here
                      style={{ width: '65%', cursor: 'pointer' }}
                      alt="Doctor 1"
                      onClick={handleClick}
                    />
                  </div>
                </div>
                <div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <img
                      src={step2}
                      className='image-border' // Add your custom class here
                      style={{ width: '65%', cursor: 'pointer' }}
                      alt="Doctor 1"
                      onClick={handleClick}


                    />
                  </div>

                </div>
                <div className="mb-5">
                  <div className='d-flex justify-content-center align-items-center'>
                    <img
                      src={step3}
                      className='image-border' // Add your custom class here
                      style={{ width: '65%', cursor: 'pointer' }}
                      alt="Doctor 1"
                      onClick={handleClick}
                    />
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </motion.div>

        <section className="booknow-section mt-100">
          <div className="text-container" data-aos="slide-up">
            <h4 >Stapleford Autocentre<br />
              Mot Testing and Servicing <br />
              <span className="all-devices">
                As an approved M.O.T. testing and servicing provider in Stapleford, we offer  <br />free M.O.T. with\ complete service (including a 60-point check) and supplementary <br /> engine  diagnostic P.O.A.Furthermore, we provide a free M.O.T. with a mini service  <br />(oil and oil filter change) for £100 .
                You can now rely on us to perform a thorough <br /> M.O.T. test to ensure your car is fit and safe for a smooth commute.
              </span>
              <span className="for-responsive">
                As an approved M.O.T. testing and servicing provider in Stapleford, we offer free M.O.T. with complete service (including a 60-point check) and supplementary  engine  diagnostic P.O.A.Furthermore, we provide a free M.O.T. with a mini service (oil and oil filter change) for £100 .
                You can now rely on us to perform a thorough  M.O.T. test to ensure your car is fit and safe for a smooth commute.
              </span>
            </h4>
            <Link to="/contact">
              <button>Book Now</button>
            </Link>

          </div>
        </section>
        {/* <section className="mt-50 booknow-section">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-center mt-50">Stapleford Autocentre<br />
                Mot Testing and Servicing <br />
                <span className="text-center mt-50 bookings">
                  As an approved M.O.T. testing and servicing provider in Stapleford, we offer free M.O.T. with <br /> complete service (including a 60-point check) and supplementary engine diagnostic P.O.A. <br />Furthermore, we provide a free M.O.T. with a mini service (oil and oil filter change) for £100 .<br />
                  You can now rely on us to perform a thorough M.O.T. test to ensure your car is fit and safe<br /> for a smooth commute.
                </span>
              </h4>
              <div className="text-center">
              <Link to="/contact">
      <button>Book Now</button>
    </Link>
              </div>
            </div>

          </div>


        </section> */}
        <section data-aos="slide-up" >
          <Slider {...stepsSetting}>
            <div class="mutliple-cards my-5 mt-5">
              <div class="container">
                <div class="left">
                  <div class="status-ind"></div>
                </div>
                <div class="right">
                  <div class="text-wrap p-3">
                    <p class="text-content"> "Top-notch service throughout! <br /> They continually exceed expectations to ensure total customer <br /> satisfaction. This is by far the finest choice!<br /> Many thanks, the Reliable Motors team." </p>
                    <p class="time">GreatWork StapleFord</p>
                  </div>
                  <h2>Alex Parker</h2>
                </div>
              </div>
            </div>
            <div class="mutliple-cards my-5 mt-5">
              <div class="container">
                <div class="left">
                  <div class="status-ind"></div>
                </div>
                <div class="right">
                  <div class="text-wrap p-3">
                    <p class="text-content"> "Top-notch service throughout! <br /> They continually exceed expectations to ensure total customer <br /> satisfaction. This is by far the finest choice!<br /> Many thanks, the Reliable Motors team." </p>
                    <p class="time">GreatWork StapleFord</p>
                  </div>
                  <h2>Diana Alexa</h2>

                </div>
              </div>
            </div>
            <div class="mutliple-cards my-5 mt-5">
              <div class="container">
                <div class="left">
                  <div class="status-ind"></div>
                </div>
                <div class="right">
                  <div class="text-wrap p-3">
                    <p class="text-content"> "Top-notch service throughout! <br /> They continually exceed expectations to ensure total customer <br /> satisfaction. This is by far the finest choice!<br /> Many thanks, the Reliable Motors team." </p>
                    <p class="time">GreatWork StapleFord</p>
                  </div>
                  <h2>Dianel James</h2>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        <section className="count-section mt-50" data-aos="slide-up" >
          <h1 className="text-center mt-4">Let’s Keep Your Vehicle
            in Top Condition</h1>
          <p className="text-center mt-4">We offer customized car repair services to cater to your needs while ensuring your vehicle is in top condition.</p>
          <div className="d-flex justify-content-center align-items-center mt-50">
            <div className="row">
              <div className="col-md-3">
                <img
                  src={carPurchase} // Use imported image
                  alt="Doctor 1"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div className="col-md-9">
                <form style={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        fullWidth
                        required
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Increase font size and change label color to #435ef7
                        }}
                        InputProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Change text color to #435ef7
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        fullWidth
                        required
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Increase font size and change label color to #435ef7
                        }}
                        InputProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Change text color to #435ef7
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Phone"
                        fullWidth
                        required
                        variant="outlined"
                        type="number"
                        placeholder="(000) 000-0000"
                        helperText="Please enter a valid phone number."
                        InputLabelProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Increase font size and change label color to #435ef7
                        }}
                        InputProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Change text color to #435ef7
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: '1rem', color: '#435ef7' }, // Change helper text color to #435ef7
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        fullWidth
                        required
                        variant="outlined"
                        placeholder="example@example.com"
                        InputLabelProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Increase font size and change label color to #435ef7
                        }}
                        InputProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Change text color to #435ef7
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        fullWidth
                        required
                        variant="outlined"
                        multiline
                        rows={4}
                        helperText="Describe your research program and development goals."
                        InputLabelProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Increase font size and change label color to #435ef7
                        }}
                        InputProps={{
                          style: { fontSize: '2rem', color: '#435ef7' }, // Change text color to #435ef7
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: '1rem', color: '#435ef7' }, // Change helper text color to #435ef7
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: '#435ef5', color: 'white', padding: 20, fontSize: '2rem' }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
          <div>

          </div>

        </section>
        <Footer />
      </div>

    </>
  );
}

export default HomePage;
