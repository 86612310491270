import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { cubicBezier } from 'framer-motion'; // Import cubicBezier
import '../../index.css'
import Footer from '../footer/footer';

const reviews = [
  {
    name: 'Samie Rees',
    location: 'UK',
    date: 'September 22, 2024',
    review: 'Kindly managed to book my car in at very short notice for MOT. Great service provided, very quick and Jimmy has a wonderful sense of humour !!!! Thanks again. Would definitely use this garage again',
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Kevin Nienaber',
    location: 'UK',
    date: 'August 22, 2024',
    review: 'Very big fan of this garage! Staff were awesome and helped when I needed both left rear and front bearings changed. Very quick and the boys know what they are doing! Will definitely be returning!',
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
{
    name: 'Sarah Flight',
    location: 'UK',
    date: 'September 20, 2024',
    review: 'Fantastic service. Jimmy was extremely helpful, fitting my car in for major repairs in an emergency. I was kept informed throughout and contacted before any work started to discuss what was needed and the price - which he kept his word on. I would highly recommend and encourage people to support an independent garage that provides excellent service. Thank you so much Jimmy!',
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
{
    name: 'roy stevenitt',
    location: 'UK',
    date: 'August 22, 2024',
    review: 'Had MOT and new rear brake pads fitted cannot recommend this garage enough, ,staff and everything about this garage spot on.I would recommend this garage to any new customers',
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Toni Attenborough',
    location: 'UK',
    date: 'July 22, 2024',
    review: 'Went here for the first time this week, Jimmy was very nice, my car needed mot but I knew it needed work before it would pass, Jimmy checked my car over told me what it needed, a fair bit of work which wasnt a surprise to me, the surprise was the price!! very good as I expected it to be more, he did everything and motd it the same day, deffeo recommend Jimmy I will be using him from now on, thanks Jimmy happy customer',
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Amy Bostock',
    location: 'UK',
    date: 'September 22, 2024',
    review: "I've always been here for my m.o.t's, good quality service every time and very reasonable prices, I wouldn't go anywhere else!",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Rhiannon Godden',
    location: 'UK',
    date: 'September 22, 2024',
    review:"Been going here for a year now and wouldn’t take my car anywhere else. They go above and beyond to help you out, even driving out in the evening when my car broke down. I find them very honest and trustworthy, and the prices are brilliant. Highly recommend. Will be back for my MOT 100%.",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Lorraine Sutton',
    location: 'UK',
    date: 'January 22, 2024',
    review: "Chose this garage due to recommendations & reviews on using local services & I wasn't disappointed. Would highly recommend. Sorted out problem with a few extra jobs that needed doing & all done the next day. Well done on your professionalism, service & efficiency. I will certainly be taking my car again when the need arises. Thank you for your first class service.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Richard Tate',
    location: 'UK',
    date: 'Feburary 10, 2024',
    review:"Highly recommend to use this garage, friendly straight and honest, showed me exactly what needed doing to my Fiat scudo van and did the work next day for a quoted price. I was very pleased when collecting and the price was cheaper now than quoted! 5 star best garage around thank you",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Jeffrey Orchard',
    location: 'UK',
    date: 'June 8, 2024',
    review:"Went in to have my car air con regassed brilliant service that impressed took a second car in for the same problem",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Martin Northfield',
    location: 'UK',
    date: 'May 15, 2024',
    review:"Jim is an absolute legend my wheel bearing went whilst in Nottingham jim fixed it within the hour,for me to get back to essex 👌",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Ian Evans',
    location: 'UK',
    date: 'July 8, 2024',
    review:"Great service - A1, 5* operation; friendly, obliging and at a very competative price. Can thoroughly recommend to anyone without any hesitation or reservation at all.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Ruhela Lawson',
    location: 'UK',
    date: 'May 25, 2023',
    review:"The service was professional, honest and very friendly. The staff were very knowledgeable and willing to go the extra mile to help. The pricing was very fair and transparent. As a woman, in other garages, I have felt that sometimes, you can be taken advantage of regarding services that are needed for your car, however, I did not feel that in this place. I was given regular updates on my car and all repairs were done efficiently and in a timely manner. I would highly recommend this garage and will be a regular returning customer.",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'tildstickley',
    location: 'UK',
    date: 'April 10, 2024',
    review:"Jimmy was really helpful and explained all aspects of the service and MOT offered, making sure I wasn't spending unnecessarily and knew exact technical details of the service. Thank you!",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Mez',
    location: 'UK',
    date: 'December 25, 2023',
    review:"Done my kia’s MOT twice Best garage in the town. friendly, best customer service, Honest, reasonable price and knowledgeable.Highly recommended Thanks Jimmy",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Taylor Bowyer',
    location: 'UK',
    date: 'July 10, 2024',
    review:"Really helped me out of some trouble. Took the proper time for a proper repair.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Kelly Gregory',
    location: 'UK',
    date: 'October 25, 2023',
    review:"After my car suddenly lost 50% power and warning lights appeared, Stapleford Auto centre got me straight in, diagnosed, ordered the parts and fitted all within 24 hours.. Leaving me with a safe car to drive. Absolutely brilliant service, even had faulty parts photos sent to my phone to prove what they had diagnosed. Would highly recommend this new business and will be using for all my future car needs. Thank you!",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Jack Photiou',
    location: 'UK',
    date: 'May 10, 2024',
    review:"Took car for a gearing issue and the guys found a piece of broken plastic wedged between the gear cables in the engine. No charge excellent service..",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Nadia Rawson',
    location: 'UK',
    date: 'November 25, 2023',
    review:"Thankyou so much for all the help and advice with my golf. You truly went above and beyond and I’d recommend to anyone in the area to bring their car here. A great job at a great price. Thanks again!!",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Carla Imani',
    location: 'UK',
    date: 'January 18, 2024',
    review:"I received a very good service from Jimmy at the Auto Centre. Honest and went above and beyond.repaired my car and even cleaned my engine. So much better than the previous owner! Thank you Jimmy from Claire..",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Helen Cutting',
    location: 'UK',
    date: 'August 01, 2023',
    review:"Fantastic service and price very friendly guys. Did a quick turnaround on my when no one else could fit me in. Under new management give them a call. Amazing. Thank you!!",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'lesley sanders',
    location: 'UK',
    date: 'Feburary 21, 2024',
    review:"Really efficient and thorough. Will definitely go there again.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'alan fairbrother',
    location: 'UK',
    date: 'March 12, 2024',
    review:"Used this place twice now,first time to fit new struts to boot lid and second time service and MOT received excellent service both times, would certainly recommend,you receive courteous and knowledgeable service and prices are reasonable.",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Samantha Smith',
    location: 'UK',
    date: 'April 14, 2024',
    review:"Very helpful. Explained what was wrong with my car and got it fixed really fast. Will be using again.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Paul Nash',
    location: 'UK',
    date: 'March 12, 2023',
    review:"I don’t normally leave comments but the bloke running the garage was soo genuine and wouldn’t rip anybody off ! Top bloke and good work done in the garage for me. Would definitely recommend.",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Laura Hutchinson',
    location: 'UK',
    date: 'April 14, 2024',
    review:"Always excellent service! As a woman going to a garage, I always feel comfortable coming here. Dan is lovely and has always got the job done, at a great price and tells you what needs doing / parts replacing. Plus bringing things to my attention/offering advice on things that might need doing in the near future . Fully recommend!!.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Paul Work',
    location: 'UK',
    date: 'April 17, 2023',
    review:"I took my CMax to them last week for a major service and MOT, great service and communication I would definitely recommend..",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Unknown Person',
    location: 'UK',
    date: 'May 18, 2023',
    review:"Thanks jimmy for all your help today, couldn’t get a tyre off and came round to help all free went back later on to get my tyre replaced so I could put it back on all for £30 really recommend going here. Might make it my go to garage.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Steve Stickley',
    location: 'UK',
    date: 'March 17, 2024',
    review:"Unparalleled kindness! Superb service ... and done with kindness. Thank you Stapleford Autocentre!..",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Joo Boy',
    location: 'UK',
    date: 'May 24, 2023',
    review:"Daniel is a rare find—uncomplicated, reasonable, respectful, and always treating his customers with courtesy and honesty. I spent a few hours at his garage after dropping in unexpectedly and was truly impressed by the positive feedback from his repeat customers. Having known him since he was young, I've seen him grow into someone with a strong old-school work ethic. After many years of not knowing where he was or what he was doing, I’m proud to see he now runs a proper British garage. Businesses like his are rare these days, so please support it!",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Ricky Brown',
    location: 'UK',
    date: 'March 08, 2022',
    review:"Did my MOT and explained all the advisory in detail,great price also,i would 100% recommend Jimmy and will be using him every time im in need..",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'Tracy OKeefe',
    location: 'UK',
    date: 'January 10, 2023',
    review:"Efficient, honest and great value for money. A marvellous job done at a great price and within a short timeframe. Will definitely use again and recommend to anyone.",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },
  {
    name: 'Qaisar Amin',
    location: 'UK',
    date: 'July 18, 2022',
    review:"Excellent car repair service, and experienced MOT expert. It was an absolute pleasure to be there...",
    cardClass: 'cardbg-1',
    headingLevel: 'h2'  // Define heading level
  },
  {
    name: 'The Maddison Girls',
    location: 'UK',
    date: 'Novemer 20, 2022',
    review:"Dan has always provided a great level of service at an excellent price. I have visited a lot of garages in my time and often feel like people are trying to pull the wool over my eyes but not here. I am confident that the work being fone is necessary and is always completed to a goid standard. Would highly recommend",
    cardClass: 'cardbg-2 d-mt',
    headingLevel: 'h4'  // Define heading level
  },


];

const ReviewCard = () => {
  const [inView, setInView] = useState(Array(reviews.length).fill(false));

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;

    const updatedInView = reviews.map((_, index) => {
      const element = document.getElementById(`review-${index}`);
      if (element) {
        const elementTop = element.getBoundingClientRect().top + window.scrollY;
        return scrollPosition > elementTop; // Check if element is in view
      }
      return false;
    });

    setInView(updatedInView);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial state

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section className="nav-link-image mt-2">
        <div className="text-container">
          <h4>Reviews</h4>
        </div>
      </section>
      <section className='reviewPage-section'>
        <div className='row'>
          {reviews.map((review, index) => (
            <div key={index} className='col-lg-6'>
              <motion.div
                id={`review-${index}`} // Unique ID for each review
                className={review.cardClass}
                initial={{
                  opacity: 0,
                  x: review.cardClass === 'cardbg-1' ? -50 : 50, // Slide in from left or right based on card class
                }}
                animate={{
                  opacity: inView[index] ? 1 : 0,
                  x: inView[index] ? 0 : (review.cardClass === 'cardbg-1' ? -50 : 50),
                  transition: {
                    duration: 0.5,
                    ease: cubicBezier(0.6, 0.05, -0.01, 0.9), // Use cubicBezier function
                  },
                }}
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }} // Slight scale on hover
                whileTap={{ scale: 0.95 }} // Scale down when tapped
              >
                {review.headingLevel === 'h2' ? (
                  <h2>
                    {review.name}
                    <span><i className="fa fa-map-marker"></i> {review.location}</span>
                  </h2>
                ) : (
                  <h4>
                    {review.name}
                    <span><i className="fa fa-map-marker"></i> {review.location}</span>
                  </h4>
                )}
                <p className='mt-4'>{review.review}</p>
                <p>Date of experience: {review.date}</p>
              </motion.div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ReviewCard;
