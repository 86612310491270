import React from "react";
import { Card } from 'antd';
import doctorImage from '../images/doctoreImages.jpg'
import aboutImage from '../images/randomPeople.png'
import vessel from '../lexus-hybrid-anim-1.gif'
import doctorImage1 from '../images/doctorImages.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import Footer from "../footer/footer";
function Terms() {
    const { Meta } = Card;
    return (
        <>
            <section className="nav-link-image">
                <div className="text-container">
                    <h4>Terms Of Services</h4>
                </div>
            </section>
            <section className="terms-services">
                <div className="row d-flex responsive mt-5">
                    <div className="col-md-12">
                        <h2>Terms of Service Agreement</h2>

                        <p>In entering or using the Services, you confirm that you have read, understood, and agree to be bound by this Terms of Service Agreement (“Agreement”) and to the collection and use of your information as outlined in our Privacy Policy, whether or not you are a registered user of our service. These terms and conditions (“Terms”) apply to all visitors, users, and others who access the service (“Users”).</p>

                        <p>Please read this Agreement carefully to ensure that you understand each provision. This Agreement contains a mandatory dispute resolution clause.</p>

                        <p>We may update these Terms at any time, and by continuing to use the website, you accept the Terms as they apply from time to time. Any changes to the Terms take immediate effect from the date of their publication on our website.</p>

                        <p>For these Terms, any reference to “Stapleford Autocentre,” “we,” “our,” or “us” means Stapleford Autocentre Pvt. Ltd, and any reference to “you” or “your” means a User of our Website.</p>

                        <h2>Acceptance of the Terms</h2>

                        <p>You accept the Terms by remaining on the website. A contract comes into existence when we receive your request for the Services governed by these Terms and our Privacy Policy.</p>

                        <p>To access the Services, you may also be required to register for an account through the website (the ‘User Account’).</p>

                        <p>By booking or registering, you agree to all the terms and conditions of our Terms and Privacy Policy which gives notice to you through our user interface as follows:</p>

                        <ul>
                            <li>By registering, you agree to read and accept our Terms and Conditions and consent to our Privacy Policy.</li>
                        </ul>

                        <p>As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:</p>

                        <ul>
                            <li>Email address</li>
                            <li>Mailing address</li>
                            <li>Telephone number</li>
                            <li>Password</li>
                            <li>Passport/Medicare/Driver’s License</li>
                        </ul>

                        <p>(“Registration Data”) If your Registration Data changes, you must promptly update your Account to reflect those changes.</p>

                        <p>You warrant that any information you give us while completing the registration process will always be accurate, correct, and up to date.</p>

                        <p>You must not impersonate, misrepresent your identity, or imitate any person when registering for a User Account.</p>

                        <p>Once you have completed the registration process, you will be a registered member of the website and agree to be bound by the Terms.</p>

                        <p>You may not use the Services and may not accept the Terms if you are not of legal age to form a binding contract with us or if you are a person barred from receiving the Services under the laws of the United Kingdom.</p>
                        <h2>Definitions</h2>

                        <p><strong>Admin</strong> means any person authorised to transact on behalf of Stapleford Autocentre.</p>

                        <p><strong>Content</strong> means any advertising and promotional material, including (without limitation) text, graphics, trademarks, copyright material, website material, documents, articles, news, newsletters, posts, commentaries, data, photographs, pictures, graphic works, video, images, scripts, software, or files.</p>

                        <p><strong>Intellectual Property Rights</strong> means all rights resulting from intellectual activity, whether capable of protection by statute, common law, or in equity anywhere in the world, whether registered or unregistered, including copyright, discoveries, inventions, moral rights, patent rights, and trademarks. Design rights, circuit layouts, plant varieties, know-how, trade secrets, the right to have confidential information kept confidential, and all rights and interests of a like nature, together with any and all documentation relating to such rights and interests, and any right of registration of such rights and interests.</p>

                        <p><strong>User Account</strong> means the account details registered by the User to access the website.</p>

                        <h2>Who May Use Our Services?</h2>

                        <p>As a condition of your use of our Services, you warrant that:</p>

                        <ul>
                            <li>You can form a binding contract with Stapleford Autocentre, and only in compliance with this Agreement and all applicable local, state, national, and international laws, rules and regulations.</li>
                            <li>You are over 17.</li>
                            <li>You are not a User previously removed from the system by Stapleford Autocentre.</li>
                        </ul>

                        <p>We retain the right in our sole discretion to deny anyone access to this website if you do not comply with our Terms.</p>

                        <h2>Our Obligations</h2>

                        <p>As part of our Services, you may order, and our representatives may provide, but are not limited to, vehicle, marine, automotive-related services, car wash, and detailing Services for your vehicle. For any other products or services we offer on our website, please refer to the warranties (if any, listed on our website and below).</p>

                        <p>We use reasonable efforts to ensure that the service will be provided professionally and satisfactorily. You understand and agree, however, that we do not guarantee that your service will be performed to meet any applicable industry or professional standards. Due to varying conditions in which a vehicle/vessel may be presented, Stapleford Autocentre cannot guarantee meeting the customer’s desired preference/expectation.</p>

                        <p>You agree that time slots are estimates, and completion times will vary depending on the condition of your vehicle. In addition to the latter, the price of Services performed may be subject to but not limited to change depending on the state of your vehicle and the customer’s desired preference (prices are all correct as of the date advertised on our website). Arrival times may be subject to delay due to but not limited to, prior bookings requiring additional time, traffic, unforeseen events, and so on.</p>

                        <p>Services offered will not include removing any parts such as, but are not limited to, removing seats, baby seats, door panels, spare tyre section, underneath the carpet, and so on due to safety concerns. Services will only be performed in the areas advertised in our packages on the website. Any additional parts that require cleaning will not be cleaned, e.g., adding extra car mats, additional boot lining, car seat covers, etc.</p>

                        <p>Extras and add-ons may be added to each booking for a more customised option and can be stacked to provide additional time.</p>

                        <p>You understand and agree that due to dangerous/poor weather conditions (for example, rain/hail), our representatives and we reserve the right to reschedule or postpone your booking.</p>

                        <p>Our representatives reserve the right to refuse to service a vehicle component prone to damage as it is already tampered with/damaged, e.g., broken or dislodged air vent and so on, to avoid further damage.</p>

                        <p>If the customer wishes for the Provider not to perform or use certain chemicals or processes, e.g., deodoriser, plastic dressings, or engine bay rinse, it is their responsibility to inform us before the booking and the Provider on the day. Otherwise, services will be performed as specified on our website. It is the customer’s responsibility to understand the health implications of the chemicals and procedures we use, e.g., if you are allergic to a specific chemical, you need to be informed.</p>

                        <h2>Potential Risks Across Services</h2>

                        <p>When working on older cars, parts become brittle and dry-rotted and may break when attempting to remove them. The customer will not hold us responsible for fragile areas, including broken fastener covers, tabs, door clips, or any other parts that may break due to the age or condition of the vehicle.</p>

                        <p>Potential risks associated with car roof lining repair include, but are not limited to, plastics and surrounding areas, such as the pillars, roof canvas, screws, clips, etc., which are prone to breaking during installation.</p>

                        <p>Potential risks of window tinting installations that the customer must accept as possible outcomes include, but are not limited to:</p>

                        <ul>
                            <li>Possible complications associated with the use of a water mixture to clean windows and install tints.</li>
                            <li>The removal of the aerial/demister grid lines when removing the rear window tinting.</li>
                            <li>The existence of dust specs in the film, which are inevitable as no tint installation can guarantee a 100% dust-free environment despite adequate cleaning procedures being actioned. Window tinting will never be perfect. Dust and imperfections can be found in any window when aftermarket tint is applied. Dot-matrix will look white or silver when the tint is applied over it. This is to be expected. Stapleford Autocentre will determine the repair of these imperfections.</li>
                            <li>Warranty for window tinting services must be communicated and addressed between the customer and installer, not Stapleford Autocentre.</li>
                            <li>Services do not include the cleaning of the parked environment or floor surface. However, Representatives will take utmost care to keep the area clean in a condition to where it was presented just before our service.</li>
                            <li>Not all demister bars and antennas are manufactured and installed perfectly; therefore, there can never be a 100% guarantee that damage will not occur. Our processes are designed to minimise possible damage and are consistent from vehicle to vehicle. If your vehicle has specific sensitivities to certain procedures, we must be advised prior.</li>
                            <li>Window tinting shades on specific windows of your vehicle may be illegal in certain states due to varying tint laws across each state. You assume all responsibilities and do not hold us responsible or liable. We only install legal tints in compliance with United Kingdom legal tint laws.</li>
                            <li>Window tint is applied with a soap and water mixture. When tinting windows (especially a windshield or rear glass), We will not be responsible for problems associated with using the water mixture. We take significant caution and care across all our installations to minimise possible risks, but problems may persist. Customers are welcome to choose another service provider as our replacement should the use of water may be of concern.</li>
                        </ul>

                        <p>Stapleford Autocentre will not be held responsible for problems associated with wiring, connections, dash cam, and so on when removing and replacing a last dash cam fitting. This is due to the internal wiring, plugs, and so on being damaged and/or tampered with before our work with the previous installation, whether performed by 3rd party installer or customer. The manufacturer must deal with any issues with the dash cam functionality for further advice.</p>

                        <p>You hereby acknowledge and agree that Stapleford Autocentre has the total liability to you for any actual or alleged damages arising out of or related to this Agreement (including, without limitation, actual or suspected damages to your vehicle resulting from a wash performed by Stapleford Autocentre representatives or your interactions with our representative) will be as is outlined in the Limitation of Liability section below.</p>

                        <p>By providing a signature, you confirm that the service was completed to your satisfaction. Anyone who is not the owner of the car and who has been delegated the task of inspecting and signing will too assume these standards/responsibilities of approval and confirmation on behalf of the owner.</p>
                        <h2>Customer Obligations</h2>

                        <p>If you choose to make a reservation for our Services, then you must:</p>

                        <ul>
                            <li>Verify the location of your vehicle before submitting a reservation;</li>
                            <li>Ensure that the Services have been granted permission to be performed at your location;</li>
                            <li>Leave your vehicle in a public location where our representative is authorised to enter and perform the Services;</li>
                        </ul>

                        <p>You understand that your responsibility is to remove all valuables from your vehicle before our representative arrives to service your car. You also understand that you should exercise reasonable judgment in locking your car doors before leaving your vehicle unattended in a public location. You agree that it is the customer’s responsibility to have their vehicle parked in the allocated service spot, not the Stapleford Autocentre technician.</p>

                        <p>It is your responsibility to ensure that you inform the representative servicing your vehicle of any defects or circumstances that are not suitable for specific tasks (for example, washing the engine bay) we carry out as advertised in the packages on our website (“Website Packages”). You agree that our representatives only carry out the tasks advertised on our website Packages. For “Engine Bay Rinses,” you accept that our representative will only carry this task out on your request, and you accept the performance of this service at your sole risk of any damage or loss to your car.</p>

                        <p>If your car or any part of it is already damaged or partially damaged or contains any defects, you agree to release and hold us harmless from any damage, aligned expectation, or loss to your car through the performance of our Services. For any specific or unusual tasks, our representatives reserve the right not to perform particular tasks where there is a risk of damaging the specific item or car part that needs cleaning (for example, pollutants, sand, or grass deeply imbedded in car mats where cleaning may result in the shredding of the mat fabric; where there is staining in the roof lining and the application of shampoo or pressure may result in destroying or weakening any existing glue adhesiveness so that your car roof sags). You accept that we can minimise stains but do not guarantee the complete removal of any stains or smells and that our representatives have the right not to undertake specific tasks where there is a risk of damage or loss to your car.</p>

                        <p>To replace the roof liner in our Roof Lining Repair Service, surrounding support components must be removed before accessing the roof lining. Although a rare occurrence, this repair service entails the potential risk of breakage/damage as older car models tend to become brittle over time.</p>

                        <p>Due to the nature of the Service, Stapleford Autocentre will not be liable for damages during the roof lining repair service for vehicles older than 10 years, e.g. (2012 and older). It will perform the service with due care.</p>

                        <p>Removal of signage/decals/stickers from the vehicle can cause discoloration. To alleviate the discrepancy, a machine buff can level the paintwork but cannot guarantee 100% blending with areas covered and not covered by the signage.</p>

                        <p>Though Stapleford Autocentre strives for perfection in our tint installations, due to the nature of the product and environment, some degree of dust contamination/specks and or minor imperfections can be present in every window film application; also, pre-existing flaws or scratches and metal deposits on the glass are often much more noticeable after the windows are tinted. The level of contamination will determine the need for replacement on a case-by-case basis.</p>

                        <p>You accept that our representatives and we have the right to cancel or reschedule our Services and charge you a cancellation fee if the location for servicing your car is unsafe or inaccessible or if you are not present, or if there are no vital access points such as power and water.</p>

                        <p>You agree that after you have performed a final inspection and/or provided your signature after the supply of the Services, you have accepted the supply of the Services and have no further claims. You are deemed to have accepted the car in its condition by taking delivery of your vehicle.</p>

                        <p>Failure to adhere to your obligations hereunder may result in our inability to provide you with our Services, and therefore you will be billed a cancellation fee.</p>

                        <p>In no event will Stapleford Autocentre be liable to you or any third party for any alleged or actual damages or losses resulting directly or indirectly from your failure to adhere to your obligations under this Agreement.</p>

                        <p>Warranty for services such as tinting will need to be followed up with the Tinting technician. Based on the tint manufacturer’s warranty, it is warranted against peeling, bubbling, cracking, de-metallising, and discolouring for as long as you own your car. Your warranty is not transferable and is limited to film replacement and associated labour costs. This warranty does not cover film damaged by scratching, glass failure, glass breakage, leakage glass, film which has been subjected to mistreatment, abuse, improper care, film which has had stickers applied, suction cups, or similar.</p>

                        <h2>User Accounts</h2>

                        <p>Your Stapleford Autocentre account gives you access to the Services and functionality that we may establish and maintain from time to time and at our sole discretion. By connecting Stapleford Autocentre with third-party Services like Facebook or Google, you give us permission to access and use your information from that service as permitted and store your log-in credentials for that service. For more information about the types of information we collect from these third-party Services and how you can adjust your settings for such Services, please review our Privacy Policy.</p>

                        <p>You may never use another User’s Account without permission. When creating your Account, you must provide accurate and complete information. You are solely responsible for the activity on your Account and must keep your account password secure. We encourage you to use “strong” passwords (passwords that use a combination of upper- and lower-case letters, numbers, and symbols) with your Account. You must notify Stapleford Autocentre immediately of any breach of security or unauthorised use of your Account. Stapleford Autocentre will not be liable for any losses caused by any unauthorised use of your Account.</p>

                        <p>By providing Stapleford Autocentre with your email address, you consent to our use of the email address to send you Service-related notices, including any notices required by law, instead of communication by postal mail.</p>

                        <h2>Online and Mobile Services Rules</h2>

                        <h3>Acceptable Use of the Online and Mobile Services</h3>

                        <p>You agree not to engage in any of the following prohibited activities:</p>

                        <ul>
                            <li>Copying, distributing, or disclosing any part of the Services in any medium, including without limitation by any automated or non-automated “scraping”;</li>
                            <li>Using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Services in a manner that sends more request messages to the Stapleford Autocentre servers than a human can reasonably produce in the same period of time by using a conventional online web browser (except that Stapleford Autocentre grants the operators of public search engines revocable permission to use spiders to copy materials from Stapleford Autocentre for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials); transmitting spam, chain letters, or other unsolicited emails.</li>
                            <li>Attempting to compromise the system integrity or security or decipher any transmissions to or from the servers running the Services.</li>
                            <li>Taking any action that imposes or may impose at our sole discretion an unreasonable or disproportionately large load on our infrastructure.</li>
                            <li>Uploading invalid data, viruses, worms, or other software agents through the Services.</li>
                            <li>Collecting or harvesting any personally identifiable information, including account names, from the Services.</li>
                            <li>Using the Services for any commercial solicitation purposes.</li>
                            <li>Impersonating another person or otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding, or attempting to hide your identity.</li>
                            <li>Interfering with the proper working of the Services.</li>
                            <li>Accessing any Content on the Services through any technology or means other than those provided or authorised by the Services; or</li>
                            <li>Bypassing the measures we may use to prevent or restrict access to the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services or the Content therein.</li>
                        </ul>

                        <h2>Changes to the Services</h2>

                        <p>We may, without prior notice, change the Services, stop providing the Services or features of the Services to you or Users generally or create usage limits for the Services. We may permanently or temporarily terminate or suspend your access to the Services without notice and liability for any reason, including if, in our sole determination, you violate any provision of this Agreement or for no reason. Upon termination for any reason or no reason, you continue to be bound by this Agreement.</p>

                        <h2>Your Use of the Website</h2>

                        <p>You must not engage in potentially harmful acts directed against our website. You must not attempt to gain unauthorised access to any portion or feature of the website or any other systems or networks connected to the website. You must not scan or test the website by hacking, password mining, or any other illegitimate actions attempting to violate any security features of the website. You must not use the software, scripts, robots, spiders, viruses, worms, Trojan horses, or other harmful code on our website or network. You agree not to use any device or software to interfere with our website’s functionality. You must not scrape, robot, or use spiders to copy any portion of the Website or the Content contained within it.</p>

                        <h2>End-User License Grant</h2>

                        <p>Stapleford Autocentre Online</p>

                        <p>Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited, non-transferable, freely revocable license to use our Online Services for your personal, non-commercial use only and as permitted by the features of the Services. Stapleford Autocentre reserves all rights not expressly granted in the Services and the Website Content. Stapleford Autocentre may terminate this license at any time for any reason or no reason.</p>
                        <h2>Our Proprietary Rights</h2>

                        <p>Our online Services, including all Website Content, and all Intellectual Property Rights related thereto, are the exclusive property of Stapleford Autocentre and its licensors. Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license in or under any such Intellectual Property Rights. You agree not to:</p>

                        <ul>
                            <li>Sell, license, rent, modify, distribute, claim, copy, reproduce, transmit, publicly display, perform, publish, adapt, edit or create derivative works from any Website Content.</li>
                            <li>Use the Website Content for any purpose not expressly permitted by this Agreement.</li>
                        </ul>

                        <p>Ideas: You may choose to, or we may invite you to, submit comments or ideas about the Services, including, without limitation, how to improve the Services or our products (“Ideas”). By submitting any Idea, you agree that your disclosure is gratuitous, unsolicited, and without restriction and will not place Stapleford Autocentre under any fiduciary or other obligation and that we are free to use the Idea without any additional compensation to you and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. By accepting your submission, you further acknowledge that Stapleford Autocentre does not waive any rights to use similar or related ideas previously known to Stapleford Autocentre, developed by its employees, or obtained from sources other than you.</p>

                        <h2>Bookings and Payment Terms</h2>

                        <p>Bookings can be made through our website located at <a href="http://www.StaplefordAutocentre.com">www.StaplefordAutocentre.com</a> or by contacting the Stapleford Autocentre team at the contact details on our website.</p>

                        <h3>Payment Policies</h3>

                        <p>If you elect to make an appointment online, over the phone, or through any other means of communication, you agree to pay the amount presented on the billing screen and as set forth on our website/confirmation email. Stapleford Autocentre may add new Services for additional fees and charges or amend fees and charges for existing Services at any time in its sole discretion. You agree that our representatives reserve the right to propose new fees and charges for the Services or increase these if the condition of your car warrants (for example, your car is filthy or requires additional time to complete the Services because of any specific or unusual circumstances). Stapleford Autocentre will bill your credit card upon completion when the representative has left your location. Any pricing changes will be communicated to the customer before execution.</p>

                        <h3>Cancellation & Late Arrival Policy</h3>

                        <p>You may cancel your (excluding tint bookings) reservation at any time before 5 pm the previous day of the booked appointment by contacting Admin at the contact details on our website. Reservations cannot be cancelled once the Stapleford Autocentre representative arrives at your destination.</p>

                        <ul>
                            <li>If a service is cancelled within 24 hours of the booked appointment, the customer may be charged up to 50% of the booking total, with a minimum cancellation fee of £50.</li>
                            <li>If you, as a customer, are not at the booked location on time, you may be charged the cancellation callout fee. It is always recommended that you contact Admin if you have any concerns regarding your arrival time or unexpected circumstances.</li>
                        </ul>

                        <h3>Cancellation Policy</h3>

                        <ul>
                            <li>Bookings rescheduled or cancelled within 24 hours will incur a £50 fee.</li>
                            <li>Bookings cancelled when the technician has arrived on site will have the total amount charged and non-refundable.</li>
                            <li>Bookings rescheduled when the technician has arrived will incur a £50 late fee and require the next booking to be scheduled in the system.</li>
                        </ul>

                        <h3>Hazardous Conditions Policy</h3>

                        <p>If your property is deemed a hazardous situation that goes over and above our typical line of work, Stapleford Autocentre professionals have the right to walk away. Upon such Services, you will be charged a £50 booking fee due to holding your allocation in our schedule. Hazardous conditions may include but are not limited to, human/pet waste/fluids, sharp objects, weapons, broken glass, insect infestations, toxic spills, and so on. Any hazardous possibilities regarding the work must be disclosed with Stapleford Autocentre during the booking so that we know the serviceability and risks involved.</p>

                        <h3>Refund Policy</h3>

                        <p>We process refunds following British Consumer Law. Except as required by law, any fees you pay are final and non-refundable.</p>

                        <p>You may reject the Services if you consider that the Services are unsatisfactory or fail to comply with the statutory guarantees under the British Consumer Law.</p>

                        <p>We will only facilitate a refund per the British Consumer Law, or, in our absolute discretion, it is reasonable to do so if we determine it is our absolute discretion.</p>

                        <h3>Payment Information & Taxes</h3>

                        <p>All information you provide concerning a purchase, transaction, or other monetary transaction interaction with the Services must be accurate, complete, and current. You agree to pay all charges incurred by users of your credit card, debit card, or other payment method used in connection with a purchase or transaction or other monetary transaction interaction with the Services at the prices in effect when such charges are incurred. All payments for our Services are in Pound Sterling (£). We will be entitled to add on G.S.T. for the supply of our Services.</p>

                        <h2>Privacy</h2>

                        <p>We care about the privacy of our Users. You understand that by using the Services, you consent to the collection, use, and disclosure of your personally identifiable information and aggregate data as outlined in our Privacy Policy, and to have your personally identifiable information collected, used, transferred to, and processed in the United Kingdom.</p>

                        <h2>Security</h2>

                        <p>Stapleford Autocentre cares about the integrity and security of your personal information. However, we cannot guarantee that unauthorised third parties will ever be able to defeat our security measures or use your personal information for improper purposes. You acknowledge that you provide your personal information at your own risk.</p>

                        <h2>Third-Party Links</h2>

                        <p>The Services may contain links to third-party websites, Services, special offers, or other events or activities not owned or controlled by Stapleford Autocentre. Stapleford Autocentre does not endorse or assume any responsibility for third-party sites, information, materials, products, or Services. You expressly relieve Stapleford Autocentre from any and all liability arising from your use of any third-party website, Services, or Content. Additionally, your dealings with or participation in promotions of advertisers found on the Services, including payment and delivery of goods and any other terms (such as warranties), are solely between you and such advertisers. You agree that Stapleford Autocentre shall not be responsible for any loss or damage relating to your dealings with such advertisers.</p>

                        <h2>Indemnity</h2>

                        <p>You agree to defend, indemnify and hold harmless Stapleford Autocentre and its subsidiaries, agents, licensors, managers, and other affiliated companies, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from:</p>

                        <ul>
                            <li>Your breach of any of these Terms;</li>
                            <li>Your willful, reckless, or negligent act;</li>
                            <li>Personal injury to any person caused or contributed to by you;</li>
                            <li>Your act or omission and constitutes a loss of or damage to property;</li>
                            <li>Any third-party claim in respect of personal injury, death, or damage to third-party property arising from your act or omission;</li>
                            <li>Your use of and access to the Services, including any car wash Services received by you and performed by Stapleford Autocentre representatives;</li>
                            <li>Any alleged or actual loss or damage to property (including your vehicle) resulting from the performance of the Services hereunder (including the car wash, boat detailing, and tinting Services);</li>
                            <li>Your violation of any term of this Agreement, including without limitation your breach of any of the obligations, representations, and warranties above;</li>
                            <li>Your violation of any third-party right, including without limitation any right of privacy or Intellectual Property Rights;</li>
                            <li>Your violation of any applicable law, rule, or regulation; and</li>
                            <li>Any other party’s access and use of the Services with your unique username, password, or other appropriate security code.</li>
                        </ul>
                        <h2>Representations & Warranties</h2>

                        <p>To the maximum extent permitted by applicable law, Stapleford Autocentre does not warrant, endorse, guarantee, or assume responsibility for the Services hereunder, including the car and boat care Services performed by Stapleford Autocentre representatives, or any losses or damages that may result from the Services, including but not limited to the car wash, tinting, roof lining, dash cam installation, boat care, and other services we offer. These Services are provided to you on an “as is” and “as available” basis.</p>

                        <p>Use of the Services is at your own risk. To the maximum extent permitted by applicable law, the Services are provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, professional quality, or non-infringement.</p>

                        <p>Without limiting the foregoing, Stapleford Autocentre, its subsidiaries, and its licensors do not warrant:</p>

                        <ul>
                            <li>That the Services are accurate, reliable, or correct;</li>
                            <li>That the Services will meet your requirements; or</li>
                            <li>That the Services will be available at any particular time or location, uninterrupted or secure.</li>
                        </ul>

                        <p>Any Content downloaded or otherwise obtained through the Services is at your own risk, and you will be solely responsible for any loss or damage through such use.</p>

                        <p>All information on this website, pursuant to these Terms, is provided in good faith. You accept that any information provided by us is general information only. We derive our information from sources we believe to be accurate and up to date as of the publication date. We reserve the right to update, change, modify, add or remove these Terms at any time.</p>

                        <p>We do not warrant that this website’s product, service descriptions, or other content is accurate, complete, reliable, current, or error-free. In addition, we do not make any representations or warranties that the information we provide is reliable, accurate, or complete or that your access to the information, products, or services on our website will be uninterrupted, timely, or secure.</p>

                        <p>We are not liable for any loss resulting from any action taken or reliance made by you on any information or material posted by us. You should make your inquiries and seek independent advice from relevant industry professionals before acting or relying on any information or material made available to you on our website or our information service. You shall ensure that any products, services, or information offered through this website meet your specific requirements.</p>

                        <h2>E-Gift Card Terms & Conditions</h2>

                        <ul>
                            <li>If you lose or misplace your e-gift card, it will not be refunded or be exchangeable for an alternative.</li>
                            <li>E-Gift cards may not be redeemed for cash and are non-refundable.</li>
                            <li>E-Gift cards can only be redeemed for the recipient address in the transaction and cannot be transferable.</li>
                            <li>All e-gift cards expire after three years from the date of issue.</li>
                            <li>E-Gift cards may be redeemed for any service under the condition that we service that geography, region, or area.</li>
                        </ul>

                        <h2>Limitation of Liability</h2>

                        <p>To the maximum extent permitted by applicable law, in no event shall Stapleford Autocentre, its affiliates, agents, directors, employees, representatives, suppliers, contractors, or licensors be liable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, that result from the use of, or inability to use, the Services performed by Stapleford Autocentre representatives. Under no circumstances will Stapleford Autocentre be responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Services or your Account or the information contained therein.</p>

                        <p>To the maximum extent permitted by applicable law, Stapleford Autocentre assumes no liability or responsibility for any:</p>

                        <ul>
                            <li>Errors, mistakes, or inaccuracies in the content;</li>
                            <li>Personal injury or property damage of any nature whatsoever resulting from our Services performed by our representatives;</li>
                            <li>The acts or omissions of our employees, contractors, or representatives serving Services on our behalf;</li>
                            <li>Any unauthorized access to or use of our secure servers and/or any and all personal information stored therein;</li>
                            <li>Any delay in the Services, including a delay of Stapleford Autocentre representatives in performing services;</li>
                            <li>The failure of Stapleford Autocentre representatives to perform the Services;</li>
                            <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our online or mobile Services by any third party;</li>
                            <li>Any errors or omissions in any Content or for any loss or damage incurred because of the use of any Content posted, emailed, transmitted, or otherwise made available through the Services;</li>
                            <li>User Content or any third party’s defamatory, offensive, or illegal conduct.</li>
                        </ul>

                        <p>In no event shall Stapleford Autocentre, its affiliates, agents, directors, employees, suppliers, or licensors be liable to you for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the amounts you paid to Stapleford Autocentre in the last 12 months hereunder or £100.00, whichever is greater.</p>

                        <p>This limitation of liability section applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if Stapleford Autocentre has been advised of such damage. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction.</p>

                        <p>Some states do not allow the exclusion of implied warranties or the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you. This Agreement gives you specific legal rights, and you may also have other rights which vary from state to state. The disclaimers, exclusions, and limitations of liability under this Agreement will not apply to the extent prohibited by applicable law.</p>

                        <p>The Services are controlled and operated from its facilities in the United Kingdom. Stapleford Autocentre makes no representations that the Services are appropriate or available for use in other locations. Those who access or use the Services from other jurisdictions do so of their own volition and are entirely responsible for compliance with all applicable United Kingdom and local laws and regulations, including but not limited to export and import regulations. Unless otherwise stated, all materials found on the Services are solely directed to individuals, companies, or other entities in the United Kingdom.</p>

                        <h2>Our Copyright & Intellectual Property</h2>

                        <p>The Intellectual Property Rights in or related to the platform or any documentation vest in Stapleford Autocentre, which has the right to use the Intellectual Property Rights.</p>

                        <p>The website and all related products and services of Stapleford Autocentre are subject to copyright protection. The material on the website is protected by copyright under the laws of the United Kingdom and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Website Content and compilation of the Website (including text, graphics, logos, button icons, video images, audio clips, and software) are owned or controlled by Stapleford Autocentre or its contributors. You may not, without the prior written permission of Stapleford Autocentre and the permission of any other relevant rights owners:</p>

                        <ul>
                            <li>Broadcast, republish, upload to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the content for any purpose.</li>
                        </ul>

                        <p>Stapleford Autocentre retains all rights, titles, and interests in and to the website, all related content, and our platform. The website or any of it (including, without limitation, any content or images) must not be copied, reproduced, adapted, publicly displayed, duplicated, translated, or distributed in any way (including mirroring) to any other device, server, site, or other media for publication or distribution, without our express prior written consent.</p>

                        <p>The Intellectual Property in our content, including any trademarks, service marks, trade names, and other proprietary designations, are owned, registered, or licensed by us. Any other trademarks, service marks, logos, trade names, and other proprietary designations are the trademarks or property of the respective parties. Your obligations under this clause survive termination or expiry of these Terms.</p>

                        <h2>Governing Law & Arbitration</h2>

                        <p>You agree that:</p>

                        <ul>
                            <li>The Services shall be deemed solely based in Stapleford.</li>
                            <li>The Services shall be deemed a passive one that does not give rise to personal jurisdiction over Stapleford Autocentre, either specific or general, in jurisdictions other than the United Kingdom.</li>
                            <li>The internal substantive laws of the United Kingdom shall govern this Agreement without respect to its conflict of laws principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded.</li>
                            <li>You agree to submit to the personal jurisdiction of a court in Nottinghamshire for any actions for which we retain the right to seek injunctive or other equitable relief, as outlined in the Arbitration provision below.</li>
                        </ul>
                        <h2>Arbitration</h2>

                        <p>For any dispute, you agree first to contact us and attempt to resolve the dispute with us informally. In the unlikely event that Stapleford Autocentre has not been able to resolve a dispute it has with you after attempting to do so informally, we each agree to resolve any claim, dispute, or controversy (excluding any Stapleford Autocentre claims for injunctive or other equitable relief) arising out of or in connection with or relating to this Agreement, or the breach or alleged breach thereof (collectively, “Claims”), by binding arbitration in Nottinghamshire.</p>

                        <p>Unless you and Stapleford Autocentre agree otherwise, the arbitration will be conducted in the county where you reside. Each party will be responsible for paying any arbitration filing, administrative, and arbitrator fees.</p>

                        <p>The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees, and reasonable costs for experts and other witnesses. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>

                        <p>Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief from the courts as necessary to prevent the actual or threatened infringement, misappropriation, or violation of that party’s data security, Intellectual Property Rights, or other proprietary rights.</p>

                        <p>All claims must be brought in the parties’ capacity and not as a plaintiff or class member in any purported class or representative proceeding. Unless we agree otherwise, the arbitrator may not consolidate more than one person’s claims.</p>

                        <p>You agree that, by entering into this Agreement, you and Stapleford Autocentre are each waiving the right to a trial by jury or to participate in a class action.</p>

                        <h2>General Disclaimer</h2>

                        <p>To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to the subject matter of this Website and our Services.</p>

                        <p>This exclusion includes, without limitation:</p>

                        <ul>
                            <li>The completeness, truth, suitability, quality, or accuracy of the information or product and service descriptions published on our website (including third-party material and advertisements);</li>
                            <li>That the information on the website is up to date;</li>
                            <li>Any product or Service or the Website will remain available.</li>
                        </ul>

                        <p>We take no responsibility for any error or omission relating to the material published on this website. You expressly understand and agree that we, our affiliates, employees, agents, contributors, and licensors shall not be liable to you for any direct, indirect, incidental, special consequential, or exemplary damages which you may incur, however caused and under any theory of liability by any information on the website. Any decisions based on such information are your sole responsibility.</p>

                        <h2>Assignment</h2>

                        <p>This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you but may be transferred or assigned by Stapleford Autocentre without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.</p>

                        <h2>Notification Procedures & Changes To The Agreement</h2>

                        <p>Stapleford Autocentre may provide notifications, whether such notifications are required by law or are for marketing or other business-related purposes, to you via email notice, written or hard copy notice, or through posting of such notice on our website, as determined by Stapleford Autocentre in our sole discretion. Stapleford Autocentre reserves the right to determine the form and means of providing notifications to our Users. It may opt out of specific means of notification as described in this Agreement. Stapleford Autocentre is not responsible for any automatic filtering you or your network provider may apply to email notifications we send to the email address you provide us. Stapleford Autocentre may, in its sole discretion, modify or update this Agreement from time to time, so you should review this page periodically. When we materially change the Agreement, we will update the ‘last modified date at the bottom of this page. Your continued use of the Services after any such change constitutes your acceptance of the new Terms of Services. If you do not agree to any of these or future Terms of Services, do not use or access (or continue to access) the Services.</p>

                        <h2>Entire Agreement</h2>

                        <p>This Agreement, with any amendments and additional agreements you may enter with Stapleford Autocentre in connection with the Services, shall constitute the entire Agreement between you and Stapleford Autocentre concerning the Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.</p>

                        <h2>No Waiver</h2>

                        <p>No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such or any other term. Stapleford Autocentre’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.</p>

                    </div>


                </div>
            </section>

            {/* <section className="">
          <div className="d-flex align-items-center justify-content-around flex-wrap mt-5">
            <div>
              <img
                src={doctorImage} // Use imported image
                alt="Doctor 1"
                style={{ width: '60%' }}
              />
            </div>
            <div>
              <div>
                <h3>About Research Mate</h3>
                <h2 className="mt-5">Experienced.
                  Committed to Quality. Inclusive..</h2>
              </div>
              <p className="mt-4 line-height">Research Mate is a network of clinical trial investigator sites.<br /> For 17 years we have been connecting patients with our <br />trusted physicians to conduct cutting-edge studies focused on <br /> quality and compassion. We contribute to the advancement of medicine and help <br />bring groundbreaking treatments to the market — <br /> lifting up the patients that need it most around the world.</p>
              <button>Read Article</button>
            </div>
          </div>
        </section>
        <section className="count-section">
        <div className="row mt-5">
        <div className="col-md-5">
              <img
                src={doctorImage1} // Use imported image
                alt="Doctor 1"
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-md-7">
              <div className="mt-5">
                <h3>Our Mission</h3>
                <h2 className="mt-5">Experienced.
                  Committed to Quality. Inclusive..</h2>
              </div>
              <p className="mt-4 line-height">Every clinical trial we conduct is overseen by one of our own physicians — otherwise known as a Principal Investigator (PI). They believe in the importance of high-quality trials done with care in order to advance life science technologies. They work within their specialty therapeutic areas and ensure each participant is treated with compassion, safety, and professionalism.

                Patients potentially get early access to life-changing medicines — sometimes even years before their release to the general public.

                Our physicians are a part of our larger team of recruiters, clinical coordinators, site managers, patient liaisons, and more. They partner together to create an infrastructure that collaborates with sponsors and patients to establish trial confidence, exceed sponsor expectations, and ever-improve the patient experience.a</p>
            </div>

          </div>
        </section>
        <section className="card-section">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="line-height">DM Clinical is currently home to 24+ dedicated research centers.<br /> We are quickly growing in demograhphically diverse areas of the country.</h2>
            </div>
            <div className="d-flex justify-content-around align-items-center flex-wrap">
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <Diversity1OutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} />  Enrolled in a COVID-19 Vaccine Trial
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <EventNoteOutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} /> Enrolled in a COVID-19 Vaccine Trial
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mutliple-cards mt-5">
                <div class="container">
                  <div class="left">
                    <div class="status-ind"></div>
                  </div>
                  <div class="right">
                    <div class="text-wrap">
                      <p class="text-content">
                        <DomainAddOutlinedIcon style={{ fontSize: 50, color: '#f26722', marginRight: 5 }} />  Enrolled in Other Studies &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                      <p class="time">Huston Fights COVID</p>
                    </div>
                    <div class="button-wrap">
                      <button class="primary-cta">Click to Fight</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
            <Footer />
        </>
    );
}

export default Terms;
