import React from "react";
import { Card } from 'antd';
import doctorImage from '../images/doctoreImages.jpg'
import aboutImage from '../images/randomPeople.png'
import vessel from '../lexus-hybrid-anim-1.gif'
import doctorImage1 from '../images/doctorImages.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from "../footer/footer";
import { motion } from 'framer-motion';
function PrivacyPolicy() {
    
    const { Meta } = Card;
    return (
        <>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
            <section className="nav-link-image">
                <div className="text-container">
                    <h4>Privacy Policy</h4>
                </div>
            </section>
            
            
            <section className="terms-services">
                <div className="row d-flex responsive mt-5">
                    <div className="col-md-12">
                        <div>
                            <h3>About Staple Ford Privacy Policy</h3>

                        </div>
                        <p className="mt-4 line-height">We respect your right to privacy and are committed to protecting the privacy of our users and website guests. This privacy policy discloses details to ensure you comprehend how we collect and use your personal information.

                            “Personal Information” refers to details we possess that are relatable as being about us.

                            Occasionally we will receive and store the personal information you enter on our website, provide it to us directly or give it to us in other forms.

                            You may provide basic information such as your name, phone number, address, and email address to enable us to send information, provide updates, reminders, confirmations and process your service order/s. We may collect additional information, including but not restricted to when you provide feedback/reviews/ratings, photos of your vehicle (before & after), when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and or promotions, provide financial or credit card information, or communicate with our customer support.

                            Additionally, we may also collect the information you provide while interacting with us</p>
                        <h2 className="mt-5">How we collect your personal information..</h2>
                        <p className="mt-4 line-height">We collect personal information from customers/visitors in numerous ways, including when you interact with us electronically, in person, when you access our website and when we provide our services. We may receive personal information from third parties. If we do, we will protect as portrayed in this privacy policy disclosure.

                            Collecting your information will comply with the law and be fair, and where appropriate, with the awareness or permission of the individual at hand.</p>
                        <h2 className="mt-5">Use of your personal information.</h2>
                        <p className="mt-4 line-height">Personal information collected from you may be used to communicate information, updates, and services to you as our customer. We may also use these details to inform you of new services, promotions, and opportunities. Ultimately, we may use your personal information to improve our services and overall customer experience to better suit your needs, for example, using information with 3rd party vendors like Google Analytics to improve website navigation for visitors. We will only keep personal information for as long as deemed necessary to fulfill its initial purpose unless we gather consent from the concerned customer or as required by law. We may also use your information, such as images of your vehicle, for marketing purposes (before/after images) and pre-inspection purposes before a wash. Pre-inspection provides an extra resource for us to highlight any visible damages.

                            We may contact you via telephone, email, SMS, in-person, or mail.

                            The materials on our website could incorporate typographical or photographic mistakes. We do not warrant that any of the materials on its site are exact, finished, or current. We may roll out improvements to the materials contained on its site whenever without notification. We do not, again, make any dedication to updating the materials.

                            Your privacy is very critical to us. Hence, we have constructed this Policy to deliver transparency in handling your data and how it will impact its use.  The following blueprints are our privacy policy.

                            Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.

                            We will gather and use individual data singularly to satisfy those reasons indicated by us and for other good purposes unless we get the consent of the individual concerned or as required by law.

                            We will hold individual data for the length of essential for those reasons.

                            We will gather individual data by legal and reasonable means and, where fitting, with the information or permission of the individual concerned.

                            Personal information ought to be important to the reasons it is to be utilised and, to the degree essential for those reasons, ought to be exact, finished, and updated.

                            We will protect individual data with security shields against misfortune or burglary and unapproved access, divulgence, duplicating, use, or alteration.

                            We will promptly provide customers access to our policies and procedures for administering individual data.

                            We are focused on leading our business as per these standards with a specific end goal to guarantee that the privacy of individual data is secure and maintained.

                            We are committed to conducting our business following these principles to ensure that the confidentiality of personal information is protected and maintained.</p>

                    </div>

                </div>
            </section>
            </motion.div>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
